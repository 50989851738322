'use client';

import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { createClient } from '@/supabase/utils/client';
import { openSnackbarState } from '@/jotai';
import { useTranslations } from 'next-intl';
export function AuthStateChangeAction() {
  const openSnackbar = useSetAtom(openSnackbarState);
  const t = useTranslations('auth');
  useEffect(() => {
    const supabase = createClient();
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION') {
        if (session === null) {} else {}
      } else if (event === 'SIGNED_IN') {} else if (event === 'SIGNED_OUT') {
        openSnackbar({
          message: t('signed-out'),
          autoHideDuration: null
        });
      } else if (event === 'PASSWORD_RECOVERY') {} else if (event === 'TOKEN_REFRESHED') {} else if (event === 'USER_UPDATED') {}
    });
  }, []);
  return null;
}